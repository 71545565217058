import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import avenirLightWoff from '../assets/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff';
import avenirLightWoff2 from '../assets/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2';
import avenirRegularWoff from '../assets/fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff';
import avenirRegularWoff2 from '../assets/fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2';
import avenirItalicWoff from '../assets/fonts/cdda031e-26e9-4269-83d1-5a218caa10db.woff';
import avenirItalicWoff2 from '../assets/fonts/1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2';
import avenirBoldWoff from '../assets/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff';
import avenirBoldWoff2 from '../assets/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2';

import fatFrankWoff from '../assets/fonts/FatFrank-Regular.woff';
import fatFrankWoff2 from '../assets/fonts/FatFrank-Regular.woff2';

const GlobalStyle = createGlobalStyle`${({ theme }) => `
  ${normalize}

  @font-face{
    font-family:"Avenir";
    src:url('${avenirLightWoff2}') format("woff2"),url('${avenirLightWoff}') format("woff");
    font-weight: ${theme.fontWeights.light};
    font-style: normal;
  }

  @font-face{
    font-family:"Avenir";
    src:url('${avenirRegularWoff2}') format("woff2"),url('${avenirRegularWoff}') format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face{
    font-family:"Avenir";
    src:url('${avenirItalicWoff2}') format("woff2"),url('${avenirItalicWoff}') format("woff");
    font-weight: normal;
    font-style: italic;
  }

  @font-face{
    font-family:"Avenir";
    src:url('${avenirBoldWoff2}') format("woff2"),url('${avenirBoldWoff}') format("woff");
    font-weight: ${theme.fontWeights.bold};
    font-style: italic;
  }

  @font-face{
    font-family:"FatFrank";
    src:url('${fatFrankWoff2}') format("woff2"),url('${fatFrankWoff}') format("woff");
    font-weight: normal;
    font-style: normal;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  ::selection {
    color: ${theme.colors.white};
    background: ${theme.colors.black};
  }

  ::-moz-selection {
    color: ${theme.colors.white};
    background: ${theme.colors.black};
  }

  html,
  body {
    -moz-osx-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased !important;
    background-color: ${theme.colors.white}; 
    color: ${theme.colors.grey950};
    font-family: ${theme.base.fontFamily};
    font-size: ${theme.base.fontSize};
    font-weight: normal;
    height: 100%;
    line-height: 1.6;
    margin: 0;
    min-height: 100%;
    min-width: 320px;
    position: relative;
    text-rendering: optimizeLegibility !important;
    width: 100%;
    z-index: 0;
  }

  a[href*="mailto:"] {
    word-wrap: break-word;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: ${theme.base.fontSize};
    outline: none;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.base.headingColor};
    margin-bottom: ${theme.spacing.small};
    margin-top: 0;
    line-height: 1.2;
  }

  h1 {
    font-family: ${theme.base.altFontFamily};
    font-weight: ${theme.fontWeights.normal};
    font-size: ${theme.fontSizes.xxxLarge};
    line-height: 1.1;

    @media (min-width: 900px) {
      font-size: ${theme.fontSizes.xxxxLarge};
    }
  }

  h2 {
    font-weight: ${theme.fontWeights.light};
    font-size: ${theme.fontSizes.xxLarge};

    @media (min-width: 900px) {
      font-size: ${theme.fontSizes.xxxLarge};
    }
  }

  h3 {
    font-weight: ${theme.fontWeights.light};
    font-size: 24px;

    @media (min-width: 900px) {
      font-size: ${theme.fontSizes.xxLarge};
    }
  }

  h4 {
    font-family: ${theme.base.altFontFamily};
    font-weight: ${theme.fontWeights.normal};
    font-size: ${theme.fontSizes.xxLarge};
    line-height: 1.1;

    @media (min-width: 900px) {
      font-size: ${theme.fontSizes.xxxxLarge};
    }
  }

  h5 {
    font-weight: ${theme.fontWeights.light};
    font-size: ${theme.fontSizes.large};

    @media (min-width: 900px) {
      font-size: ${theme.fontSizes.xLarge};
    }
  }

  h6 {
    font-size: ${theme.fontSizes.small};
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  strong {
    color: ${theme.colors.black};
    font-weight:  ${theme.fontWeights.bold};
  }

  {/* Reset Lists */}
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  {/* Images */}
  figure {
    margin: 0;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${theme.colors.black};
    text-decoration: underline;
    font-weight: ${theme.fontWeights.bold}; 

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: ${theme.spacing.small};

    b,
    strong {
      font-weight: bold;
    }

    i,
    em {
      font-style: italic;
    }
  }

  svg {
    display: inline-block;
  }

  a,
  input,
  select,
  textarea,
  button,
  svg,
  [class^="c-btn"] {
    transition-duration: ${theme.base.transitionSpeed};
    transition-property: background-color, color, opacity, border, border-left, border-right, border-color, transform, fill;
    transition-timing-function: ${theme.easing.move};
    transform-origin: center center;
  }
`}`;

export default GlobalStyle;
