import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';
import ImageLoader from './ImageLoader';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  margin-top: -${props => props.theme.components.headerHeightMarketing};
  padding-top: ${props => props.theme.spacing.xLarge};
  width: 100%;
  overflow: hidden;
`;

const TextArea = styled.div`
  position: relative;
  z-index: 2;

  ${props => props.theme.breakpoints.large`
    width: 56%;
  `}

  h3 {
    color: ${props => props.theme.colors.red500};
  }
`;

const ImageArea = styled.figure`
  height: 70vw;
  display: flex;

  ${props => props.theme.breakpoints.large`
    width: 50vw;
    position: absolute;
    left: 50vw;
    bottom: 0;
    top: 0;
    min-width: 70vw;
    z-index: 0;
    height: auto;
  `}

  ${props => props.theme.breakpoints.xLarge`
    min-width: 1000px;
  `}

  ${props => props.theme.breakpoints.xxLarge`
    left: auto;
    right: 0;
  `}
`;

const MarketingHero = ({
  hero_small: small,
  hero_background_color: backgroundColor,
  hero_title: title,
  hero_sub_title: subTitle,
  hero_text: text,
  hero_button_text: buttonText,
  hero_button_url: buttonUrl,
  hero_image: image,
  ...props
}) => {
  return (
    <Wrapper>
      <Section
        padding={small ? 'small' : 'medium'}
        backgroundColor={backgroundColor}
      >
        <Container>
          <TextArea>
            <TextContentMarketing {...props}>
              <h1>{extractPrismicString(title)}</h1>
              {hasContent(subTitle) && (
                <h3>{extractPrismicString(subTitle)}</h3>
              )}

              {extractPrismicContent(text)}
            </TextContentMarketing>

            {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
              <Button green marginTop href={extractPrismicString(buttonUrl)}>
                {extractPrismicString(buttonText)}
              </Button>
            )}
          </TextArea>
        </Container>

        <ImageArea>
          {!!(image && image.url) && (
            <ImageLoader auto contain alignBottom imageUrl={image.url} />
          )}
        </ImageArea>
      </Section>
    </Wrapper>
  );
};

MarketingHero.propTypes = {};

export default MarketingHero;
