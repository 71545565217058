import { css } from 'styled-components';
import * as utils from './utils';

const palette = {
  white: '#FFFFFF',
  black: '#000000',
  grey950: '#171D1A',
  grey900: '#012731',
  grey800: '#34525A',
  grey500: '#677D83',
  grey300: '#CCD4D6',
  grey200: '#E6E9EA',
  grey100: '#FAFBFB',
  green100: '#EDF8F6',
  green200: '#E3F4F0',
  green300: '#DAF1EC',
  green320: '#D1EDE7',
  green350: '#B6E2D9',
  green400: '#91D4C7',
  green450: '#6DC5B4',
  green500: '#48B7A1',
  green600: '#3A9281',
  green800: '#005F58',
  red100: '#FFEFEC',
  red300: '#FFE0D9',
  red350: '#FFC1B3',
  red400: '#FFA18D',
  red450: '#FF8267',
  red500: '#FF6341',
  red600: '#CC4F34',
  yellow100: '#FEF8F4',
  yellow300: '#FCF2E8',
  yellow350: '#F9E5D1',
  yellow400: '#F7D8BB',
  yellow450: '#F4CBA4',
  yellow500: '#F1BE8D',
  yellow600: '#C19871',
  blue500: '#416C92',
  greenBlue500: '#40bad4',
  greenBlue600: '#40bad4',
};

export const colors = {
  ...palette,
  buttonPrimary: palette.red500,
  buttonActive: palette.red600,
  buttonDisabled: '#CECCCC',
};

const fontSizes = {
  xxxxLarge: utils.toRems('56px'),
  xxxLarge: utils.toRems('44px'),
  xxLarge: utils.toRems('32px'),
  xLarge: utils.toRems('22px'),
  large: utils.toRems('20px'),
  medium: utils.toRems('18px'),
  base: utils.toRems('16px'),
  small: utils.toRems('14px'),
  xSmall: utils.toRems('12px'),
  xxSmall: utils.toRems('10px'),
};

const fontWeights = {
  bold: 700,
  semibold: 600,
  medium: 500,
  normal: 400,
  light: 300,
};

const main = {
  colors,
  fontSizes,
  fontWeights,

  spacing: {
    xxSmall: '5px',
    xSmall: '10px',
    small: '15px',
    base: '20px',
    medium: '25px',
    midLarge: '30px',
    large: '40px',
    xLarge: '80px',
    xxLarge: '100px',
    xxxLarge: '130px',
  },

  base: {
    fontColor: colors.grey500,
    headingColor: colors.black,
    fontSize: '16px',
    fontFamily: "'Avenir', sans-serif",
    altFontFamily: "'FatFrank', sans-serif",
    radius: '10px',
    radiusSmall: '4px',
    transitionSpeed: '250ms',
    transitionSpeedSlow: '500ms',
    shadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },

  grid: {
    maxWidth: '1280px',
    maxWidthXnarrow: '800px',
    maxWidthNarrow: '900px',
    gutter: '30px',
  },

  easing: {
    enter: 'cubic-bezier(0, 0.5, 0.5, 1)',
    exit: 'cubic-bezier(0.5, 0, 0.5, 1)',
    move: 'cubic-bezier(0.5, 0, 0, 1)',
  },

  layers: {
    modal: 9000,
    header: 8000,
    footerBar: 7000,
    dropdown: 6000,
  },

  components: {
    headerHeight: '70px',
    headerHeightMarketing: '109px',
    footerBar: '86px',
    tableMaxWidth: '1280px',
    tableMaxWidthSmall: '900px',
    chartHeight: '320px',
    sidebarWidth: '400px',
  },

  breakpoints: {
    small: (...args) => css`
      @media (min-width: 600px) {
        ${css(...args)};
      }
    `,
    mediumDown: (...args) => css`
      @media (max-width: 900px) {
        ${css(...args)};
      }
    `,
    medium: (...args) => css`
      @media (min-width: 900px) {
        ${css(...args)};
      }
    `,
    large: (...args) => css`
      @media (min-width: 1024px) {
        ${css(...args)};
      }
    `,
    xLarge: (...args) => css`
      @media (min-width: 1280px) {
        ${css(...args)};
      }
    `,
    xxLarge: (...args) => css`
      @media (min-width: 2024px) {
        ${css(...args)};
      }
    `,
  },

  typography: {
    body: {},

    heading1: {},

    heading2: {},
  },
};

export default main;
