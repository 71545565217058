import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.green320};
  width: 100%;
  overflow: hidden;
  position: relative;

  h2 {
    display: flex;
    align-items: center;
  }
`;

const TextArea = styled.div`
  ${props => props.theme.breakpoints.large`
    width: 66.66%;
    padding-right: ${props.theme.spacing.large};
  `}
`;

const RightArea = styled.div`
  background-image: url('/background-tile.jpg');
  background-size: 500px 500px;
  color: ${props => props.theme.colors.white};
  padding: 0 ${props => props.theme.spacing.midLarge};

  h4 {
    color: ${props => props.theme.colors.white};
  }

  ${props => props.theme.breakpoints.large`
    width: 33.33%;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
  `}

  ${props => props.theme.breakpoints.xLarge`
    padding: 0 ${props.theme.spacing.xLarge};
  `}
`;

const Icon = styled.img`
  width: 60px;
  margin-right: ${props => props.theme.spacing.base};

  ${props => props.theme.breakpoints.medium`
  margin-right: ${props.theme.spacing.large};
    width: 100px;
  `}
`;

const MarketingHero = ({
  sign_up_cta_title: title,
  sign_up_cta_text: text,
  sign_up_cta_icon: icon,
  sign_up_cta_signup_title: signUpTitle,
  sign_up_cta_signup_text: signUpText,
  sign_up_cta_signup_button_text: buttonText,
  sign_up_cta_signup_button_url: buttonUrl,
}) => {
  return (
    <Wrapper>
      <Container>
        <Section padding="large">
          <TextArea>
            <TextContentMarketing>
              <h2>
                {!!(icon && icon.url) && <Icon src={icon.url} />}
                {extractPrismicString(title)}
              </h2>
              {extractPrismicContent(text)}
            </TextContentMarketing>
          </TextArea>
        </Section>
      </Container>
      <RightArea>
        <Section padding="large">
          <TextContentMarketing>
            <h4>{extractPrismicString(signUpTitle)}</h4>
            {extractPrismicContent(signUpText)}
          </TextContentMarketing>

          {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
            <Button whiteMono marginTop href={extractPrismicString(buttonUrl)}>
              {extractPrismicString(buttonText)}
            </Button>
          )}
        </Section>
      </RightArea>
    </Wrapper>
  );
};

MarketingHero.propTypes = {};

export default MarketingHero;
