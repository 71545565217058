import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import ImageLoader from './ImageLoader';
import TextContentMarketing from './TextContentMarketing';
import Button from './Button';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${props => props.theme.breakpoints.medium`
    display: flex;
  `}
`;

const TextArea = styled.div`
  ${props => props.theme.breakpoints.medium`
    padding-right: 10%;
  `}

  ${props =>
    props.imageWidth === 'small' &&
    props.theme.breakpoints.medium`
    width: 70%;
  `}

${props =>
  props.imageWidth === 'medium' &&
  props.theme.breakpoints.medium`
    width: 60%;
  `}

${props =>
  props.imageWidth === 'large' &&
  props.theme.breakpoints.medium`
    width: 50%;
  `}

  ${props =>
    props.reverse &&
    props.theme.breakpoints.medium`
      margin-left: auto;
      padding-right: 0;
      padding-left: 10%;
    `}

  h2 {
    color: ${props => props.theme.colors.red500};
  }
`;

const ImageArea = styled.figure`
  display: flex;
  height: 400px;

  ${props => props.theme.breakpoints.medium`
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    height: auto;
  `}

  ${props =>
    props.imageWidth === 'small' &&
    props.theme.breakpoints.medium`
    width: 30vw;
  `}

  ${props =>
    props.imageWidth === 'medium' &&
    props.theme.breakpoints.medium`
      width: 40vw;
    `}

  ${props =>
    props.imageWidth === 'large' &&
    props.theme.breakpoints.medium`
      width: 50vw;
    `}

  ${props =>
    props.reverse &&
    props.theme.breakpoints.medium`
      right: auto;
      left: 0;
    `}
`;

const MarketingSplitContent = ({
  split_content_small: small,
  split_content_background_color: backgroundColor,
  split_content_image_width: imageWidth,
  split_content_reverse: reverse,
  split_content_image: image,
  split_content_fit_image: fitImage,
  split_content_title: title,
  split_content_sub_title: subTitle,
  split_content_text: text,
  split_content_button_text: buttonText,
  split_content_button_url: buttonUrl,
  ...props
}) => {
  return (
    <Wrapper>
     {!!(image && image.url)&& <ImageArea imageWidth={imageWidth} reverse={reverse}>
        {!!(image && image.url) && (
          <ImageLoader auto imageUrl={image.url} contain={fitImage} />
        )}
      </ImageArea>}

      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          <TextArea imageWidth={imageWidth} reverse={reverse}>
            <TextContentMarketing fullWidthUl>
              <h2>{extractPrismicString(title)}</h2>
              {hasContent(subTitle) && (
                <h3>{extractPrismicString(subTitle)}</h3>
              )}

              {extractPrismicContent(text)}
            </TextContentMarketing>

            {!!(hasContent(buttonText) && hasContent(buttonUrl)) && (
              <Button green marginTop href={extractPrismicString(buttonUrl)}>
                {extractPrismicString(buttonText)}
              </Button>
            )}
          </TextArea>
        </Container>
      </Section>
    </Wrapper>
  );
};

MarketingSplitContent.propTypes = {};

export default MarketingSplitContent;
