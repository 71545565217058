import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import NavigationMarketing from './NavigationMarketing';
import Container from './Container';

const Wrapper = styled.header`
  color: ${props => props.theme.colors.black};
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: ${props => props.theme.layers.header};

  ${props => props.theme.breakpoints.small`
    height: ${props.theme.components.headerHeightMarketing};
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: block;
  width: 80px;

  ${props => props.theme.breakpoints.medium`
    width: 150px;
  `}

  & svg {
    display: block;
    fill: ${props => props.theme.colors.black};
    width: 80px;

    ${props => props.theme.breakpoints.medium`
      width: 150px;
      height: 48px;
    `}
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }

  span {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

const HeaderMarketing = ({ ...props }) => {
  const siteName = process.env.GATSBY_SITE_NAME;

  return (
    <Wrapper>
      <Container {...props}>
        <Inner>
          <LogoLink to="/">
            <svg viewBox="0 0 865 267" xmlns="http://www.w3.org/2000/svg">
              <path d="M438.828 52.0684C434.038 46.224 428.122 43.3015 421.081 43.3009C418.126 43.2729 415.197 43.8479 412.472 44.9909C409.911 46.0521 407.59 47.6187 405.648 49.597C403.706 51.5753 402.183 53.9247 401.169 56.505C400.062 59.2576 399.506 62.2004 399.532 65.1669C399.5 68.1684 400.056 71.1471 401.169 73.9347C402.201 76.5252 403.736 78.8857 405.685 80.8801C407.634 82.8744 409.959 84.4632 412.525 85.5547C415.195 86.6949 418.072 87.2703 420.975 87.2447C427.594 87.2447 433.545 84.4278 438.828 78.794V103.301L436.715 104.041C433.824 105.09 430.857 105.919 427.841 106.523C425.161 107.045 422.438 107.31 419.708 107.315C408.826 107.315 398.377 103.048 390.606 95.4315C386.709 91.5802 383.573 87.0289 381.362 82.0158C379.005 76.6776 377.816 70.8969 377.877 65.0616C377.809 59.265 378.979 53.5206 381.31 48.2128C384.619 40.7672 390.019 34.4427 396.855 30.0078C403.69 25.5728 411.666 23.2182 419.814 23.23C422.957 23.2275 426.091 23.564 429.162 24.2336C432.47 24.9818 435.705 26.0246 438.828 27.3496V52.0684Z" />
              <path d="M502.629 91.2588H473.051L468.298 105.097H446.22L476.537 25.4487H499.143L529.46 105.097H507.383L502.629 91.2588ZM497.136 75.5193L487.84 49.0052L478.544 75.5193H497.136Z" />
              <path d="M604.776 105.097H579.001L559.247 74.4629V105.097H538.543V25.4487H570.761C574.723 25.3668 578.664 26.0297 582.381 27.403C585.385 28.5075 588.11 30.2577 590.364 32.53C592.618 34.8024 594.346 37.5416 595.427 40.5545C596.533 43.6009 597.088 46.8204 597.064 50.0615C597.064 56.1189 595.603 61.0309 592.68 64.7975C589.757 68.5655 585.444 71.1183 579.74 72.4558L604.776 105.097ZM559.247 61.0473H563.156C567.239 61.0473 570.373 60.2023 572.557 58.5122C573.647 57.649 574.514 56.5361 575.084 55.2675C575.654 53.999 575.91 52.6119 575.832 51.2234C575.91 49.8349 575.654 48.4479 575.084 47.1793C574.514 45.9108 573.647 44.7979 572.557 43.9347C570.374 42.2446 567.24 41.3995 563.156 41.3994H559.247V61.0473Z" />
              <path d="M658.755 42.9839H634.142V56.2939H657.381V73.8292H634.142V87.5616H658.755V105.097H613.438V25.4487H658.755V42.9839Z" />
              <path d="M718.966 42.9839H694.353V56.2939H717.593V73.8292H694.353V87.5616H718.966V105.097H673.648V25.4487H718.966V42.9839Z" />
              <path d="M800.092 105.097H774.317L754.563 74.4629V105.097H733.859V25.4487H766.077C770.039 25.3668 773.98 26.0297 777.697 27.403C780.701 28.5075 783.426 30.2577 785.68 32.5301C787.935 34.8024 789.663 37.5416 790.743 40.5545C791.85 43.6009 792.404 46.8204 792.38 50.0615C792.38 56.1189 790.919 61.0309 787.996 64.7975C785.073 68.5655 780.76 71.1183 775.056 72.4558L800.092 105.097ZM754.563 61.0473H758.472C762.555 61.0473 765.689 60.2023 767.873 58.5122C768.963 57.649 769.83 56.5361 770.4 55.2675C770.97 53.999 771.226 52.6119 771.148 51.2234C771.226 49.8349 770.97 48.4479 770.4 47.1793C769.83 45.9108 768.963 44.7979 767.873 43.9347C765.689 42.2446 762.556 41.3995 758.472 41.3994H754.563V61.0473Z" />
              <path d="M852.486 45.8362C850.456 44.1397 848.176 42.7677 845.726 41.7692C843.645 40.9235 841.423 40.4755 839.177 40.4486C836.844 40.3454 834.542 41.0136 832.627 42.35C831.83 42.906 831.181 43.6497 830.739 44.5155C830.297 45.3813 830.074 46.3427 830.091 47.3147C830.045 48.5973 830.499 49.8477 831.359 50.8008C832.31 51.7936 833.439 52.5997 834.687 53.1774C836.182 53.8939 837.737 54.4771 839.335 54.9205C841.059 55.4138 842.767 55.942 844.458 56.505C851.218 58.7597 856.166 61.7703 859.3 65.5368C862.433 69.3051 863.999 74.2171 864 80.2728C864.055 84.0699 863.355 87.8401 861.94 91.3644C860.606 94.6199 858.547 97.5282 855.92 99.8679C853.085 102.338 849.779 104.207 846.201 105.361C841.989 106.721 837.582 107.381 833.155 107.315C823.115 107.326 813.324 104.185 805.163 98.3362L814.036 81.6462C816.765 84.1497 819.865 86.2164 823.226 87.7731C826.044 89.0653 829.104 89.7492 832.204 89.7802C835.584 89.7802 838.102 89.0056 839.757 87.4563C840.928 86.4418 841.74 85.0767 842.073 83.5637C842.406 82.0508 842.242 80.4708 841.606 79.0584C841.108 78.0899 840.383 77.2563 839.493 76.6284C838.302 75.7782 837.006 75.0858 835.637 74.5684C834.053 73.9347 832.134 73.2304 829.88 72.4557C827.203 71.6106 824.58 70.6775 822.01 69.6565C819.517 68.6816 817.198 67.3081 815.144 65.5895C813.085 63.8324 811.427 61.6528 810.285 59.1987C808.951 56.1193 808.319 52.7813 808.437 49.4274C808.385 45.7605 809.049 42.1186 810.39 38.7056C811.624 35.6045 813.495 32.7973 815.884 30.4661C818.366 28.1012 821.317 26.2838 824.546 25.1316C828.222 23.8196 832.104 23.1756 836.007 23.23C840.179 23.2601 844.331 23.8101 848.367 24.8673C852.666 25.9614 856.816 27.5754 860.725 29.6737L852.486 45.8362Z" />
              <path d="M394.683 193.791L425.682 162.018H441.723L405.721 198.072L441.93 241.656H425.837L397.159 206.221L394.683 208.697V241.656H382.666V162.018H394.683V193.791Z" />
              <path d="M472.176 162.018V241.656H460.159V162.018H472.176Z" />
              <path d="M520.785 173.314V241.656H508.767V173.314H490.456V162.018H539.044V173.314H520.785Z" />
              <path d="M613.288 166.299V180.535C606.341 174.724 599.155 171.818 591.728 171.818C587.853 171.745 584.006 172.493 580.442 174.013C576.877 175.533 573.674 177.791 571.044 180.638C568.277 183.471 566.102 186.828 564.65 190.513C563.197 194.197 562.495 198.135 562.585 202.095C562.496 206.008 563.2 209.899 564.653 213.533C566.107 217.167 568.281 220.47 571.044 223.242C573.717 226.034 576.939 228.241 580.508 229.724C584.077 231.206 587.915 231.932 591.779 231.856C595.48 231.961 599.159 231.257 602.559 229.793C604.353 229.028 606.072 228.095 607.691 227.008C609.637 225.707 611.505 224.294 613.288 222.777V237.271C606.702 241.079 599.231 243.089 591.624 243.1C586.213 243.19 580.84 242.181 575.831 240.133C570.821 238.085 566.28 235.041 562.482 231.185C558.605 227.431 555.538 222.922 553.471 217.936C551.404 212.951 550.381 207.594 550.464 202.198C550.398 192.231 553.975 182.583 560.522 175.067C568.775 165.336 579.452 160.471 592.552 160.47C599.862 160.514 607.025 162.527 613.288 166.299Z" />
              <path d="M650.187 193.171H684.54V162.018H696.558V241.656H684.54V204.467H650.187V241.656H638.17V162.018H650.188L650.187 193.171Z" />
              <path d="M767.087 173.314H735.159V192.45H766.159V203.746H735.159V230.36H767.087V241.656H723.142V162.018H767.087V173.314Z" />
              <path d="M790.37 241.656V156.602L848.448 217.362V162.018H860.466V246.505L802.388 185.899V241.656H790.37Z" />
              <path d="M133.495 266.991C59.8856 266.991 0 207.105 0 133.495C0 59.8858 59.8856 0 133.495 0V48.4145C110.93 48.4145 89.2896 57.3783 73.3339 73.3341C57.3781 89.2898 48.4143 110.93 48.4143 133.495C48.4143 156.06 57.3781 177.701 73.3339 193.657C89.2896 209.612 110.93 218.576 133.495 218.576V266.991Z" />
              <path d="M135.148 133.478C208.112 132.72 267.239 73.14 267.239 0H218.824C218.799 22.557 209.827 44.1828 193.877 60.1331C177.926 76.0833 156.301 85.0554 133.744 85.081V181.874C156.301 181.9 177.926 190.872 193.877 206.822C209.827 222.772 218.799 244.398 218.824 266.955H267.239C267.239 193.815 208.112 134.235 135.148 133.478Z" />
            </svg>

            <span>{siteName}</span>
          </LogoLink>

          <NavigationMarketing {...props} />
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default HeaderMarketing;
