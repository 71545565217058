import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';
import ImageLoader from './ImageLoader';

import { extractPrismicString, hasContent } from '../utils/prismic';

const Inner = styled.div`
  ${props => props.theme.breakpoints.medium`
    display: flex;
  `}
`;

const TitleArea = styled.div`
  ${props => props.theme.breakpoints.medium`
    width: 25%;
    flex: 0 0 25%;
  `}
`;

const LogoArea = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${props => props.theme.spacing.xSmall};

  ${props => props.theme.breakpoints.medium`
    width: 75%;
    flex: 0 0 75%;
  `}
`;

const LogoItem = styled.li`
  width: 50%;
  flex: 0 0 50%;
  padding: 0 ${props => props.theme.spacing.xSmall};

  ${props => props.theme.breakpoints.small`
    width: 25%;
    flex: 0 0 25%;
  `}
`;

const MarketingLogoGrid = ({
  logo_grid_small: small,
  logo_grid_background_color: backgroundColor,
  logo_grid_title: title,
  items,
}) => {
  return (
    <Section
      padding={small ? 'medium' : 'large'}
      backgroundColor={backgroundColor}
    >
      <Container>
        <Inner>
          <TitleArea>
            {hasContent(title) && <h3>{extractPrismicString(title)}</h3>}
          </TitleArea>

          <LogoArea>
            {items?.map(
              ({ logo_grid_link_url: url, logo_grid_image: image }) => (
                <LogoItem>
                  <a href={url} target="_blank" rel="noreferrer noopener">
                    <ImageLoader imageUrl={image?.url} />
                  </a>
                </LogoItem>
              ),
            )}
          </LogoArea>
        </Inner>
      </Container>
    </Section>
  );
};

MarketingLogoGrid.propTypes = {};

export default MarketingLogoGrid;
