import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import { colors } from '../theme/main';

import Button from './Button';

const Nav = styled.nav`
  flex: 1 1 auto;
  margin-left: ${(props) => props.theme.spacing.large};
`;

const MobileNav = styled.nav`
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.white};
  opacity: 0;
  visibility: hidden;
  transition: opacity ${(props) => props.theme.base.transitionSpeed}
      ${(props) => props.theme.easing.move} 0ms,
    visibility 0ms ${(props) => props.theme.easing.move}
      ${(props) => props.theme.base.transitionSpeed};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${(props) =>
    props.isVisible &&
    `
    opacity: 1;
    visibility: visible;
    transition: opacity ${props.theme.base.transitionSpeed}
    ${props.theme.easing.move} 0ms, visibility 0ms
    ${props.theme.easing.move} 0ms
  `}
`;

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  ${(props) =>
    props.mobile &&
    props.theme.breakpoints.medium`
    display: none;
  `}

  ${(props) =>
    props.desktop &&
    `
    display: none;
  `}

  ${(props) =>
    props.desktop &&
    props.theme.breakpoints.medium`
    display: flex;
  `}

  ${(props) =>
    props.mobileMenu &&
    `
    display: block;
  `}
`;

const NavItem = styled.li`
  list-style: none;
  padding: 0;
  margin: 0 6px;

  ${(props) => props.theme.breakpoints.small`
    margin: 0 ${props.theme.spacing.base};
  `}

  a {
    font-size: ${(props) => props.theme.fontSizes.base};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 1;
    text-decoration: none;
    border-bottom: solid 2px transparent;

    &:hover {
      border-color: ${(props) => props.theme.colors.green300};
    }

    ${(props) =>
      props.signup &&
      `
        color: ${props.theme.colors.white};

        &:hover {
          border-color: transparent;
        }
      `}
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    svg {
      display: block;
      width: 30px;
    }
  }

  ${(props) =>
    props.mobileMenu &&
    `
    margin: 30px 6px;

    a {
      font-size: ${props.theme.fontSizes.large};
    }
  `}
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 30px;

  svg {
    display: block;
    width: 20px;
  }
`;

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          borderColor: isCurrent && colors.green500,
        },
      };
    }}
  />
);

const NavigationMarketing = () => {
  const [hasAccount, setHasAccount] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const siteUrl = process.env.GATSBY_SITE_URL;

  useEffect(() => {
    setHasAccount(
      Cookies.get('ck-has-account', { domain: siteUrl }) === 'true',
    );
  }, [siteUrl]);

  const handleShowMobileMenu = () => {
    setShowMobileMenu(true);
  };

  const handleHideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      <ScrollLock isActive={showMobileMenu} />

      <Nav>
        <Menu mobile>
          <NavItem signup>
            <Button
              green
              xSmall
              href={
                hasAccount
                  ? `${process.env.GATSBY_APP_URL}`
                  : `${process.env.GATSBY_APP_URL}/sign-up`
              }
            >
              {hasAccount ? 'Log in' : 'Get Started'}
            </Button>
          </NavItem>

          <NavItem>
            <button type="button" onClick={handleShowMobileMenu}>
              <img
                src={'/burger.svg'}
                style={{ height: '20px', width: '20px' }}
              />
            </button>
          </NavItem>
        </Menu>

        <Menu desktop>
          <NavItem>
            <NavLink to="/our-program">Our Program</NavLink>
          </NavItem>

          <NavItem>
            <NavLink to="/about-us">About Us</NavLink>
          </NavItem>

          <NavItem>
            <NavLink to="/blog">Resources</NavLink>
          </NavItem>

          <NavItem>
            <a href={`${process.env.GATSBY_APP_URL}`}>Log in</a>
          </NavItem>

          <NavItem signup>
            <Button green small href={`${process.env.GATSBY_APP_URL}/sign-up`}>
              Get Started
            </Button>
          </NavItem>
        </Menu>
      </Nav>

      <TouchScrollable>
        <MobileNav isVisible={showMobileMenu}>
          <CloseButton type="button" onClick={handleHideMobileMenu}>
            <img src={'/close.svg'} style={{ height: '20px', width: '20px' }} />
          </CloseButton>
          <Menu mobileMenu>
            <NavItem mobileMenu>
              <NavLink to="/our-program">Our Program</NavLink>
            </NavItem>

            <NavItem mobileMenu>
              <NavLink to="/about-us">About Us</NavLink>
            </NavItem>

            <NavItem mobileMenu>
              <NavLink to="/blog">Resources</NavLink>
            </NavItem>

            <NavItem mobileMenu>
              <a href={`${process.env.GATSBY_APP_URL}`}>Log in</a>
            </NavItem>

            <NavItem mobileMenu>
              <a href={`${process.env.GATSBY_APP_URL}/sign-up`}>Get Started</a>
            </NavItem>
          </Menu>
        </MobileNav>
      </TouchScrollable>
    </>
  );
};

export default NavigationMarketing;
