import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BarLoader from 'react-spinners/BarLoader';

import buttonBase from '../theme/buttonBase';
import getButtonElement from '../utils/getButtonElement';
import getColorFromLevel from '../utils/getColorFromLevel';
import getTintColorFromLevel from '../utils/getTintColorFromLevel';

const Wrapper = styled.a`
  ${({
    theme,
    small,
    xSmall,
    level,
    disabled,
    fullWidth,
    secondary,
    secondaryWhite,
    round,
    dark,
    green,
    yellow,
    marginTop,
    marginTopLarge,
    marginRight,
    white,
    whiteMono,
  }) => `

  ${buttonBase}

  ${
    small
      ? `

      font-size: ${theme.fontSizes.small};
      height: 40px;
      min-width: 0;
  `
      : ``
  }

  ${
    xSmall
      ? `

      font-size: ${theme.fontSizes.small};
      height: 40px;
      min-width: 0;
      padding: ${theme.spacing.xxSmall} ${theme.spacing.small};
      white-space: nowrap;
  `
      : ``
  }

  ${
    secondary
      ? `

      background-color: transparent;
      color: ${theme.colors.grey900}!important;
      border: solid 1px ${theme.colors.grey900};

      &:hover {
        background-color: transparent;
        border-color: ${theme.colors.grey500};
        color: ${theme.colors.grey500} !important;
      }
    `
      : ``
  }

  ${
    secondaryWhite
      ? `

      background-color: transparent;
      color: ${theme.colors.white}!important;
      border: solid 1px ${theme.colors.white};

      &:hover {
        background-color: transparent;
        border-color: ${theme.colors.white};
        color: ${theme.colors.white}!important;
      }
    `
      : ``
  }

  ${
    fullWidth
      ? `
      min-width: 0;
    width: 100%;
  `
      : ``
  }

  ${
    level
      ? `
      background-color: ${getColorFromLevel({ level, theme })}

      &:hover {
        background-color: ${getTintColorFromLevel({ level, theme })}
      }
      `
      : ``
  }

  ${
    disabled
      ? `
    pointer-events: none;
    opacity: 0.5;
  `
      : ``
  }


  ${
    round
      ? `
      border-radius: 100%;
      width: 40px;
      height: 40px;
      min-width: 40px;
      padding: ${theme.spacing.xSmall};

      svg {
        width: 15px;
        height: 15px;
        display: block;
        fill: ${theme.colors.white};
      }
  `
      : ``
  }

  ${
    dark
      ? `
      background-color: ${theme.colors.black};

      &:hover {
        background-color: ${theme.colors.grey800};
      }
  `
      : ``
  }

  ${
    green
      ? `
      background-color: ${theme.colors.green500};

      &:hover {
        background-color: ${theme.colors.green600};
      }
  `
      : ``
  }

  ${
    yellow
      ? `
      background-color: ${theme.colors.yellow500};

      &:hover {
        background-color: ${theme.colors.yellow600};
      }
  `
      : ``
  }

  ${
    white
      ? `
      background-color: ${theme.colors.white};
      color: ${theme.colors.red500}!important;

      &:hover {
        background-color: ${theme.colors.red100};
      }
  `
      : ``
  }

  ${
    whiteMono
      ? `
      background-color: ${theme.colors.white};
      color: ${theme.colors.black}!important;

      &:hover {
        background-color: ${theme.colors.green100};
      }
  `
      : ``
  }

  ${
    marginTop
      ? `
      margin-top: ${theme.spacing.base}
  `
      : ``
  }

  ${
    marginTopLarge
      ? `
      margin-top: ${theme.spacing.large}
  `
      : ``
  }

  ${
    marginRight
      ? `
      margin-right: ${theme.spacing.xSmall}
  `
      : ``
  }
`}
`;

const Button = ({ children, loading, disabled, noRerender, ...props }) => {
  const elementType = getButtonElement(props);
  const ElementType = Wrapper.withComponent(elementType);

  const renderContent = () => {
    if (loading) {
      return <BarLoader color="#ffffff" size={30} loading={loading} />;
    }

    return children;
  };

  return useMemo(() => {
    return (
      <ElementType {...props} disabled={disabled || loading}>
        {renderContent()}
      </ElementType>
    );
  }, [disabled, loading, props.onClick, children]); //eslint-disable-line
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  disabled: false,
};

export default Button;
