import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import StaticGlobalStyle from '../theme/StaticGlobalStyle';
import main from '../theme/main';

const StaticThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={main}>
      <StaticGlobalStyle />

      {children}
    </ThemeProvider>
  );
};

StaticThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StaticThemeProvider;
