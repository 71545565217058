import React from 'react';
import styled from 'styled-components';
import { extractPrismicString } from '../utils/prismic';

import Container from './Container';
import Section from './Section';
import Button from './Button';

const Inner = styled.div`
  display: flex;
  justify-content: flex-start;

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}
`;

const MarketingButton = ({
  button_link_small: small,
  button_link_background_color: backgroundColor,
  button_link_center: center,
  button_link_text: text,
  button_link_url: url,
}) => {
  return (
    <Section
      padding={small ? 'medium' : 'large'}
      backgroundColor={backgroundColor}
    >
      <Container>
        <Inner center={center}>
          <Button href={extractPrismicString(url)}>
            {extractPrismicString(text)}
          </Button>
        </Inner>
      </Container>
    </Section>
  );
};

MarketingButton.propTypes = {};

export default MarketingButton;
