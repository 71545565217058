import React from 'react';
import styled from 'styled-components';

import Container from './Container';

const Wrapper = styled.footer`
  padding: ${props => props.theme.spacing.base} 0;
  position: relative;
  z-index: 1;

  ${props => props.theme.breakpoints.medium`
    padding: ${props.theme.spacing.large} 0 0 0;
  `}
`;

const Inner = styled.div`
  color: ${props => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${props => props.theme.fontSizes.xSmall};

  ${props => props.theme.breakpoints.medium`
    flex-direction: row;
  `}

  ${props =>
    props.dark &&
    `
      color: ${props.theme.colors.grey500};
  `}
`;

const MetaLinks = styled.div`
  ${props => props.theme.breakpoints.medium`
    margin-left: ${props.theme.spacing.base};
  `}

  a {
    color: ${props => props.theme.colors.white};
    display: inline-block;
    padding-right: ${props => props.theme.spacing.xxSmall};

    ${props => props.theme.breakpoints.medium`
 
      padding: ${props.theme.spacing.xSmall};
    `}

    ${props =>
      props.dark &&
      `
      color: ${props.theme.colors.grey500};
  `}
  }
`;

const FooterStatic = ({ ...props }) => {
  const siteName = process.env.GATSBY_SITE_NAME;

  return (
    <Wrapper>
      <Container>
        <Inner {...props}>
          © Copyright 2020 {siteName} Ltd. All rights reserved.
          <MetaLinks {...props}>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-condition-of-supply">Terms & Condition of supply</a>
            <a href="/disclaimer">Disclaimer</a>
          </MetaLinks>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default FooterStatic;
