import React, { useState } from 'react';
import styled from 'styled-components';
import {
  extractPrismicString,
  hasContent,
  extractPrismicContent,
} from '../utils/prismic';

import Container from './Container';
import Section from './Section';
import Button from './Button';
import Modal from './Modal';
import TextContentMarketing from './TextContentMarketing';

const Wrapper = styled.div`
  h2 {
    color: ${props => props.theme.colors.red500};
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-start;

  h2 {
    color: ${props => props.theme.colors.red500};
  }

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}
`;

const Video = styled.div`
  background-color: ${props => props.theme.colors.grey200};
  display: block;
  overflow: hidden;
  padding-bottom: ${(9 / 16) * 100}%;
  position: relative;
  width: 100%;
  flex: 0 0 0;

  iframe {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const MarketingVideo = ({
  video_title: title,
  video_text: text,
  video_small: small,
  video_background_color: backgroundColor,
  video_button_center: center,
  video_button_text: buttonText,
  video_embed: embbed,
  video_show_button: showButton,
}) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleToggleVideoModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  return (
    <Wrapper>
      <Section
        padding={small ? 'medium' : 'large'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {!!(hasContent(title) || hasContent(text)) && (
            <TextContentMarketing marginBottom>
              {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}
              {hasContent(text) && extractPrismicContent(text)}
            </TextContentMarketing>
          )}
        </Container>
        <Container xNarrow={small}>
          {showButton ? (
            <Inner center={center}>
              <Button onClick={handleToggleVideoModal}>
                {extractPrismicString(buttonText)}
              </Button>
            </Inner>
          ) : (
            <Video
              dangerouslySetInnerHTML={{ __html: embbed && embbed.html }}
            />
          )}
        </Container>
      </Section>

      {showButton && (
        <Modal isVisible={showVideoModal} onClose={handleToggleVideoModal} />
      )}
    </Wrapper>
  );
};

MarketingVideo.propTypes = {};

export default MarketingVideo;
