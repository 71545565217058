import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import HeaderMarketing from './HeaderMarketing';
import FooterStatic from './FooterStatic';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;

  ${(props) =>
    props.hasFooterBar &&
    `
    padding-bottom: calc(${props.theme.components.footerBar} + ${props.theme.spacing.midLarge});
  `}
`;

const Main = styled.main`
  min-height: calc(
    100vh - ${(props) => props.theme.components.headerHeight} -
      ${(props) => props.theme.spacing.xLarge}
  );
  position: relative;

  ${(props) =>
    props.fullHero &&
    `
    min-height: 0;
  `}
`;

const LayoutStatic = ({ title, children, ...props }) => {
  const siteName = process.env.GATSBY_SITE_NAME;
  const siteUrl = process.env.GATSBY_SITE_URL;
  const siteMetaDescription = process.env.GATSBY_SITE_META_DESCRIPTION;
  const favIconURL = process.env.GATSBY_FAVICON_URL;

  return (
    <Wrapper>
      <Helmet>
        <title>{`${siteName} - ${title}`}</title>
        <link rel="icon" href={favIconURL} />

        <meta
          key="description"
          content={siteMetaDescription}
          name="description"
        />
        <meta content="website" property="og:type" />
        <link href={siteUrl} rel="canonical" />

        <meta content={title} itemProp="name" />
        <meta content={siteMetaDescription} itemProp="description" />

        <meta content={title} property="og:title" />
        <meta content="website" property="og:type" />
        <meta content={siteUrl} property="og:url" />
        <meta content="/open-graph.jpg" property="og:image" />
        <meta content={siteMetaDescription} property="og:description" />
      </Helmet>

      <HeaderMarketing marketing {...props} />

      <Main>{children}</Main>

      <FooterStatic dark />
    </Wrapper>
  );
};

LayoutStatic.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default LayoutStatic;
