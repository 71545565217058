const getTypeFromPrimary = primary => {
  const firstValueKey =primary&&Object.entries(primary)?.filter(
    ([key, value]) => !!value,
  )?.[0]?.[0];

  if (!firstValueKey) return null;

  if (firstValueKey.includes('hero')) {
    return 'hero';
  }

  if (firstValueKey.includes('hero_with_sign_up')) {
    return 'hero_with_sign_up';
  }

  if (firstValueKey.includes('sign_up_banner')) {
    return 'sign_up_banner';
  }

  if (firstValueKey.includes('content_grid')) {
    return 'content_grid';
  }

  if (firstValueKey.includes('split_content')) {
    return 'split_content';
  }

  if (firstValueKey.includes('sign_up_cta')) {
    return 'sign_up_cta';
  }

  if (firstValueKey.includes('contact_us_banner')) {
    return 'contact_us_banner';
  }

  if (firstValueKey.includes('title_banner')) {
    return 'title_banner';
  }

  if (firstValueKey.includes('text_content')) {
    return 'text_content';
  }

  if (firstValueKey.includes('feature_image')) {
    return 'feature_image';
  }

  if (firstValueKey.includes('feature_list')) {
    return 'feature_list';
  }

  if (firstValueKey.includes('testimonials')) {
    return 'testimonials';
  }

  if (firstValueKey.includes('case_study')) {
    return 'case_study';
  }

  if (firstValueKey.includes('faqs')) {
    return 'faqs';
  }

  if (firstValueKey.includes('logo_grid')) {
    return 'logo_grid';
  }

  if (firstValueKey.includes('video')) {
    return 'video';
  }

  if (firstValueKey.includes('button')) {
    return 'button';
  }

  return null;
};

export default getTypeFromPrimary;
