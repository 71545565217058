import React from 'react';

import MarketingHero from './MarketingHero';
import MarketingSignUpBanner from './MarketingSignUpBanner';
import MarketingContentGrid from './MarketingContentGrid';
import MarketingSplitContent from './MarketingSplitContent';
import MarketingSignUpCta from './MarketingSignUpCta';
import MarketingContactUsBanner from './MarketingContactUsBanner';
import MarketingTitleBanner from './MarketingTitleBanner';
import MarketingTextContent from './MarketingTextContent';
import MarketingFeatureImage from './MarketingFeatureImage';
import MarketingFeatureList from './MarketingFeatureList';
import MarketingHeroWithSignup from './MarketingHeroWithSignup';
import MarketingTestimonials from './MarketingTestimonials';
import MarketingCaseStudy from './MarketingCaseStudy';
import MarketingFaqs from './MarketingFaqs';
import MarketingLogoGrid from './MarketingLogoGrid';
import MarketingButton from './MarketingButton';
import MarketingVideo from './MarketingVideo';
import MarketingVideoCarousel from './MarketingVideoCarousel';

import getTypeFromPrimary from '../utils/getTypeFromPrimary';

const ContentBlock = ({ type, primary, items, fields, ...props }) => {
  const blockType = type || getTypeFromPrimary(primary);

  if (blockType === 'hero') {
    return <MarketingHero {...primary} />;
  }

  if (blockType === 'hero_with_sign_up') {
    return <MarketingHeroWithSignup {...primary} />;
  }

  if (blockType === 'sign_up_banner') {
    return <MarketingSignUpBanner {...primary} />;
  }

  if (blockType === 'content_grid') {
    return <MarketingContentGrid items={items || fields} {...primary} />;
  }

  if (blockType === 'split_content') {
    return <MarketingSplitContent {...primary} />;
  }

  if (blockType === 'sign_up_cta') {
    return <MarketingSignUpCta {...primary} />;
  }

  if (blockType === 'contact_us_banner') {
    return <MarketingContactUsBanner {...primary} />;
  }

  if (blockType === 'title_banner') {
    return <MarketingTitleBanner {...primary} {...props} />;
  }

  if (blockType === 'text_content') {
    return <MarketingTextContent {...primary} />;
  }

  if (blockType === 'feature_image') {
    return <MarketingFeatureImage {...primary} />;
  }

  if (blockType === 'feature_list') {
    return <MarketingFeatureList items={items || fields} {...primary} />;
  }

  if (blockType === 'testimonials') {
    return <MarketingTestimonials items={items || fields} {...primary} />;
  }

  if (blockType === 'case_study') {
    return <MarketingCaseStudy items={items || fields} {...primary} />;
  }

  if (blockType === 'faqs') {
    return <MarketingFaqs items={items || fields} {...primary} />;
  }

  if (blockType === 'logo_grid') {
    return <MarketingLogoGrid items={items || fields} {...primary} />;
  }

  if (blockType === 'button') {
    return <MarketingButton {...primary} />;
  }
  if (blockType === 'video') {
    return <MarketingVideo {...primary} />;
  }
  if (blockType === 'testimonials_videos') {
    return <MarketingVideoCarousel {...primary} items={items || fields} />;
  }

  return null;
};

export default ContentBlock;
