import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import Section from './Section';

import { extractPrismicString, hasContent } from '../utils/prismic';
import ImageLoader from './ImageLoader';

const Wrapper = styled.div`
  text-align: center;

  h2 {
    color: ${props => props.theme.colors.red500};
    margin-bottom: ${props => props.theme.spacing.large};
  }
`;

const MarketingFeatureImage = ({
  feature_image_small: small,
  feature_image_background_color: backgroundColor,
  feature_image_title: title,
  feature_image_image: image,
}) => {
  return (
    <Wrapper>
      <Section
        padding={small ? 'small' : 'medium'}
        backgroundColor={backgroundColor}
      >
        <Container>
          {hasContent(title) && <h2>{extractPrismicString(title)}</h2>}

          {!!(image && image.url) && (
            <ImageLoader
              contain
              sixteenByNine
              noBackground
              imageUrl={image.url}
              alt={extractPrismicString(title)}
            />
          )}
        </Container>
      </Section>
    </Wrapper>
  );
};

MarketingFeatureImage.propTypes = {};

export default MarketingFeatureImage;
