import React from 'react';

import LayoutStatic from './LayoutStatic';
import StaticThemeProvider from './StaticThemeProvider';
import ContentBlock from './ContentBlock';

import { extractPrismicString } from '../utils/prismic';

const StaticPage = ({
  title,
  page_description: description,
  body1: blocks,
}) => {
  if (!blocks) return null;

  return (
    <StaticThemeProvider>
      <LayoutStatic
        title={extractPrismicString(title)}
        description={description}
      >
        {blocks.map(block => (
          <ContentBlock {...block} />
        ))}
      </LayoutStatic>
    </StaticThemeProvider>
  );
};

export default StaticPage;
