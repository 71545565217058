// Import Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

const Wrapper = styled.figure`
  background-color: ${props => props.theme.colors.grey200};
  display: block;
  overflow: hidden;
  padding-bottom: ${(3 / 4) * 100}%;
  position: relative;
  width: 100%;
  z-index: 1;

  img {
    display: none;
  }

  ${props =>
    props.screenshot &&
    `
    padding-bottom: 36%;
  `};

  ${props =>
    props.square &&
    `
    padding-bottom: 100%;
  `};

  ${props =>
    props.sixteenByNine &&
    `
    padding-bottom: ${(9 / 16) * 100}%;
  `};

  ${props =>
    props.noBackground &&
    `
    background-color: transparent;
  `};

  ${props =>
    props.auto &&
    `
    background-color: transparent;
    padding-bottom: 0;
    height: auto;
  `};

  ${props =>
    props.marginTop &&
    `
    margin-top: ${props.theme.spacing.large};
  `};

  ${props =>
    props.circle &&
    `
    border-radius: 100%;
    overflow: hidden;
  `};
`;

const Background = styled.div`
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1.1);
  background-image: url(${props => props.imageUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity ${props => props.theme.base.transitionSpeed}
      ${props => props.theme.easing.enter} 0s,
    transform ${props => props.theme.base.transitionSpeed}
      ${props => props.theme.easing.enter} 0s;

  ${props =>
    props.isLoaded &&
    `
    opacity: 1;
    transform: none;
  `};

  ${props =>
    props.contain &&
    `
    background-size: contain;
  `};

  ${props =>
    props.alignBottom &&
    `
    background-position: bottom right;
  `};

  ${props =>
    props.noBorder &&
    `
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  `};
`;

const ImageLoader = ({ imageUrl, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = handleImageLoad;

    return () => {
      img.src = null;
      img.onload = null;
    };
  });

  return (
    <Wrapper {...props}>
      <LazyLoad>
        <Background isLoaded={isLoaded} imageUrl={imageUrl} {...props}>
          <img src={imageUrl} alt={alt} />
        </Background>
      </LazyLoad>
    </Wrapper>
  );
};

ImageLoader.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ImageLoader;
